import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Nav from "./nav_manager";
import Footer from "../admin/footer";
import axios from 'axios';
import { useState, useEffect } from "react";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Dashboard() {

    const [list,setList]=useState([])
   
    const [user,setUser]=useState(null);

    const [selected,setSelected]=useState(null)
    const [agentList,setAgentList]=useState([])

    const [listold,setListold]=useState([])
    const [listoldStatus,setListoldStatus]=useState(false)

    
  
    useEffect(() => {
      const a=localStorage.getItem("Manager");
      if(a==null)
      {
        window.location.replace("/login")
      }else{
        setUser(a)
        getInq(JSON.parse(a)[0].team);
        TeamList("Agent",JSON.parse(a)[0].team)
    
       
      }
  
  
    
    
    }, []);

    const getfilter_Agent = status => {
      const a=list;
      if(listoldStatus)
      {
        const newArray = a.filter(item => item.name_agent === status);
        setList(newArray)
        console.log("array s ",newArray)
      }else{
        setListold(a)
        setListoldStatus(true)
        const newArray = a.filter(item => item.name_agent === status);
        setList(newArray)
        console.log("array ",newArray)
  
      }
  
    
      
    }

    const TeamList = async (typ,id)=> {
      const data = new FormData();
          data.append('team_id',id);
          data.append('type',typ);
              axios({
                method: 'post',
                url: '/api_lock/manager/team_agent.php',
                data: data,
                headers: {'Content-Type': 'multipart/form-data'},
              })
                .then(s => {
                   if(typ=="Agent"){
  
                      setAgentList(s.data)
                  }
      
             console.log(s.data)
      
      
      
                   
           
                })
                .catch(s => {
                  console.log('here catch =>>', s);
                });
            };


  const getInq = async (id)=> {
    const data = new FormData();
    data.append('team_id',id);
            axios({
              method: 'post',
              url: '/api_lock/manager/pending_view.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            })
              .then(s => {
                setList(s.data)
                console.log(s.data)
                
         
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };


  const revert_inq_same_team = async (inq_id)=> {
    const data = new FormData();
    data.append('id',inq_id);
            axios({
              method: 'post',
              url: '/api_lock/manager/manager_agent_return.php',
              data: data,
              headers: {'Content-Type': 'multipart/form-data'},
            }).then(s => {
                getInq(JSON.parse(user)[0].team)
                console.log(s.data)                
              })
              .catch(s => {
                console.log('here catch =>>', s);
              });
          };



          const return_agent = async (id)=> {
            const data = new FormData();
                data.append('id',id);
               
                    axios({
                      method: 'post',
                      url: '/api_lock/admin/return_agent_to_manager.php',
                      data: data,
                      headers: {'Content-Type': 'multipart/form-data'},
                    })
                      .then(s => {
                        alert(getInq(JSON.parse(user)[0].team))
                 
                      })
                      .catch(s => {
                        console.log('here catch =>>', s);
                      });
                  };




  return (
  <>

  <Nav />



    <div className="page-container" style={{backgroundColor:"#F9FBFD"}}>
        {/* Content Wrapper START */}
        <div className="main-content">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="m-b-0">Total Pending View </p>
                      <h2 className="m-b-0">
                        <span>{list.length}</span>
                      </h2>
                    </div>
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i class="anticon anticon-check"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       
      
        
          </div>
          
        

    <div className="card">
   <div style={{paddingTop:"10px",paddingLeft:"20px"}}>
   <h4>Pending view <span onClick={()=>{getInq(JSON.parse(user)[0].team);}} ><i style={{color:"#FFC107"}}  className="anticon anticon-undo"></i> </span></h4>
   <br/>
   
   <div className='row' >

    <div className='col-3' >
    <Autocomplete
      disablePortal
      id="size-small-standard"
        size="small"
       value={selected}
        onChange={(event, newValue) => {
            console.log(newValue)
            setSelected(newValue)  
            if(newValue===null)
            {
            }else{
              getfilter_Agent(newValue.name)
            }

        }}
      options={agentList}
      // sx={{ width: 100 }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li"  {...props}>
          
          {option.name}
        </Box>
      )}
      renderInput={(params) => <TextField {...params}  label="Agent Name" />}
    />
    </div>
    <div className='col-3' >
    {
  listoldStatus?        <button style={{margin:"10px"}} onClick={()=>{
    const a=listold;
    setList(a)
    setListoldStatus(false)
    setSelected()
   
    
  }}  class="btn btn-success m-r-5">Reset</button>:""
}
    </div>
   </div>
  

   </div>
   <div className="card-body">
 <div>
      <div id="data-table_wrapper" className="dataTables_wrapper ">
      
        <div className="row">
          <div className="col-sm-12">
            <table
              id="data-table"
              className="table dataTable"
              role="grid"
              aria-describedby="data-table_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_asc">
                    ID
                  </th>
                  <th
                    className="sorting_asc">
                    Website
                  </th>
                  <th className="sorting">
                   Inquire Date
                  </th>

                  <th
                    className="sorting"
                  >
                    Agent Received Date
                  </th>
                  <th
                    className="sorting">
                    Agent Name
                  </th>  
                 
                  <th
                    className="sorting">
                    Action
                  </th>  
                  <th
                    className="sorting">
                    Revert
                  </th>  

                  
                  {/* <th
                    className="sorting">
                    Return (Dashboard)
                  </th>   */}
                 
                 
                 
                </tr>
              </thead>
              <tbody>
      {
        list.map((v)=>(   
        

              <>
                <tr role="row" className="odd">
                  <td>{v.id} </td>
                  <td>{v.web}</td>
                  <td>{v.date_portal} {v.date_time} </td>
                  
                  <td>{v.inquiry_agent}</td>
                  <td>{v.name_agent}</td>
                
                
                  <td>
                  <a href={"View_inquiry_manager?id="+v.id}> 
                  <HtmlTooltip
                  sx={{borderRadius: 2,  minWidth: 300,}}
        title={
          <div >
<center>
           <div className='row'>
            <div className='col-12'>Email</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><b>{v.email}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Phone</div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><b>{v.phone}</b></div>
           </div>
           <div className='row'>
            <div className='col-12'>Adult / Child /Infant </div>
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><b>{v.adult} / {v.child} / {v.infant}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Name</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.name}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Dep</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.dep}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Arri</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.arri}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Mak</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.makkah}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Man</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.madina}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Type</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.type}</b></div>
           </div>
        
           <div className='row'>
            <div className='col-3'>Acc</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.acc}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Date</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_portal}</b></div>
           </div>
           <div className='row'>
            <div className='col-3'>Time</div>
            <div className='col-9' style={{color:"red",fontWeight: 'bold'}} ><b>{v.date_time}</b></div>
           </div>


           <div className='row'>
            
            <div className='col-12' style={{color:"red",fontWeight: 'bold'}} ><a target="_blank" href={v.url}>{v.url}</a></div>
           </div>
                
</center>
        

         
          </div>
        }
      >
           <button  className="btn btn-warning m-r-5">Info</button>
      </HtmlTooltip>
      </a>
      </td>
      <td><button onClick={()=>{ revert_inq_same_team(v.id)}} className="btn btn-icon btn-warning"> <i className="anticon anticon-close"></i>
</button> </td>
      
     
                </tr>
           
               
              </>
           

      ))
      }
        </tbody>
        </table>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>
      <div >


     
        
        
      </div>
      
    
    </div>





        </div>
        {/* Content Wrapper END */}
        {/* Footer START */}
<Footer />
        {/* Footer END */}
      </div>
      
  </>
  );
}